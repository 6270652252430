import ThemeProvider from 'react-bootstrap/ThemeProvider';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { recordActions } from '../../../store/record-slice';
import { basicActions } from '../../../store/basic-slice';
import { RecordWrap, DayRecordBox, DayEditBtn } from './Record.styled';
import Header,{ LoHeader } from '../../atom/Header/Header';
import Wrapper from "../../atom/Wrapper/Wrapper";
import Title from '../../atom/Title/Title';
import Footer from "../../atom/Footer/Footer";
import Btn from '../../atom/Btn/Btn';
import Form from 'react-bootstrap/Form';
import { userActions } from '../../../store/user-slice';


const RecordDay = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    //날짜 파라미터에서 년월 추출
    const [year, month] = params.number.split('-').slice(0, 2);
    const currentMonth = `${year}-${month}`;
    
    const user_id = localStorage.getItem('id');
    const main_user_id = useSelector((state) => state.user.main_user_id);
    const [dayedit, setDayEdit] = useState(false);
    const recordData = useSelector((state) => state.record.monthlyData);
    
    const isLoading = useSelector((state) => state.basic.isLoading);

    const main_user_recommend_food = useSelector((state) => state.user.user_recommend_food);
    const [editfoodlist, setEditFoodList] = useState([]);
    
    const UserRecordSet = async () => { //사용자 기록 저장 API호출
        try{
            const response = await axios.post('/record/api/v1/user_record_set/', {
                user_card_id: main_user_id,
                record_date: params.number,
                foods: editfoodlist
            },
            {headers: {
                "Content-Type": "application/json",}});

        }catch(error){
            alert('기록 저장 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
        }finally{
            // 데이터 저장 후 바로 불러오지 않고, 일정 시간(예: 1초)이 지난 후에 UserRecord 함수 호출
            setTimeout(() => {
                UserRecord();
            }, 2000); // 1초 후에 호출되도록 설정
            dispatch(
                basicActions.setisLoading({
                    isLoading: false
                }));

        }
    };

    const UserRecordDelete = async () => { //사용자 기록 삭제 API호출
        try{
            const response = await axios.post('/record/api/v1/user_record_delete/',{
                user_card_id: main_user_id,
                record_date: params.number
            },
            {headers: {
                "Content-Type": "application/json",}});

            // 데이터 저장 후 바로 불러오지 않고, 일정 시간(예: 1초)이 지난 후에 UserRecord 함수 호출
            setTimeout(() => {
                UserRecord();
            }, 1000); // 1초 후에 호출되도록 설정
        }catch(error){
            alert('기록 삭제 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
        }finally{
            dispatch(
                basicActions.setisLoading({
                    isLoading: false
                }));
        }
    }

    // 현재 날짜의 월 사용자기록 다시 불러오기
    const UserRecord = async() => {
        // AbortController를 생성하고 타임아웃을 30초로 설정합니다.
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 30000);
        let errorCaught = false; // 오류를 캐치했는지 여부를 추적하는 변수

        try{
            const response = await axios.post('/record/api/v1/user_record_get_month/', {
                user_card_id: main_user_id,
                year: year,
                month: month
            },
            {headers: {
                "Content-Type": "application/json",},
            signal: controller.signal, // AbortController를 fetch에 전달합니다.
            });
            // 타임아웃 전에 응답이 왔다면 clearTimeout을 호출하여 타임아웃을 취소합니다.
            clearTimeout(timeoutId);

            // 성공적인 응답 처리
            dispatch(
                recordActions.recordget({
                    month: currentMonth,
                    data: response.data
                })
            );
                //사용자 추천 푸드 리스트 호출, 추천식품 변경
                try{
                    const response0 = await axios.post('/food/api/v1/user_recommend_food/',{
                        user_id: user_id,
                        user_card:  main_user_id,
                    },
                    {headers: {
                        "Content-Type": "application/json",}});
                    //사용자 추천 푸드 리스트 history 호출
                    try{
                        const response2 = await axios.post('/food/api/v1/user_recommend_food_history/', {
                            is_active: true,
                            user_id: user_id,
                            user_card:  main_user_id,
                        },
                        {headers: {
                            "Content-Type": "application/json",}});

                        dispatch(
                            userActions.setuserrecommendfood({
                                user_recommend_food: response2.data.details
                            })
                        )
                        // localstorage에 저장할 데이터
                        const result = {
                            user_id: user_id,
                            user_card: main_user_id,
                            data: response2.data.details,
                        };
                        //로컬스토리지 저장
                        localStorage.setItem('myFoodData', JSON.stringify(result));

                        //사용자 추천 제품 호출
                        try{
                            const response3 = await axios.post('/food/api/v1/user_recommend_product/', {
                                user_id: user_id,
                                user_card: main_user_id,
                            },
                            {headers: {
                                "Content-Type": "application/json",}});

                            dispatch(
                                userActions.setuserrecommendproduct({
                                    user_recommend_product: response3.data.detail
                                })
                            );
                            // localstorage에 저장할 데이터
                            const result2 = {
                                user_id: user_id,
                                user_card: main_user_id,
                                data: response3.data.detail,
                            };
                            //로컬스토리지 저장
                            localStorage.setItem('myproductData', JSON.stringify(result2));

                        }catch(error){
                            alert('제품 데이터 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
                        }
                    }catch(error){
                        alert('식품 데이터 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
                    }
                }catch(error){
                    alert('추천식품 변경 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
                }

        }catch(error){
            // 오류를 캐치한 경우, 중복오류 메시지가 뜨지 않도록
            if (!errorCaught) { // 오류를 캐치한 적이 없는 경우에만 동작을 수행
                errorCaught = true; // 오류를 캐치했음을 표시
                alert('사용자 기록 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
            }
        }finally{
            dispatch(
                basicActions.setisLoading({
                    isLoading: false
                }));
            window.location.reload();
        }
    };

    useEffect(()=>{
        if(recordData[currentMonth] == null){
            dispatch(
                basicActions.setisLoading({
                    isLoading: true
            }));
            if (main_user_id !== 0){
                UserRecord();
            }
        }
    },[main_user_id])

    const handleSave = () =>{
        if (window.confirm("기록을 저장하시겠습니까? 기록 저장 후 추천식품이 변경됩니다.") == true){ 
            dispatch(
                basicActions.setisLoading({
                    isLoading: true
                }));

            UserRecordSet();
            setDayEdit(false);
            //true는 확인버튼을 눌렀을 때 코드 작성
            // console.log('완료됨')
          }else{
            // false는 취소버튼을 눌렀을 때, 취소됨
            // console.log("취소되었습니다");
          }
    };

    const handleDelete = () =>{
        if (window.confirm("기록을 삭제하시겠습니까?") == true){ 
            dispatch(
                basicActions.setisLoading({
                    isLoading: true
                }));

            UserRecordDelete();
          }
    };

    return(
        <ThemeProvider
        breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
        minBreakpoint="xxs">
            <Header value={
                <LoHeader style={{justifyContent:'space-between'}}>
                    <Title onClick={()=>navigate("/")} className="logo" value="구매 기록" />
                </LoHeader>} />
            <Wrapper box={{position:'relative'}}>
                {/* 기록 추가 버튼 클릭 시 나오는 모달창 */}
                <Offcanvas show={dayedit} onHide={() => setDayEdit(false)} placement='bottom' style={{height:'auto', maxWidth:'800px', margin: '0 auto', borderRadius:'4px'}}>
                        <Offcanvas.Header closeButton>
                            <Title className="medium w_800" style={{padding: '8px 0'}}
                                value={params.number} />
                        </Offcanvas.Header>
                    <RecordWrap style={{overflowY:'scroll', padding:0}}>
                        <DayRecordBox>
                            <ul>
                                {main_user_recommend_food?.map((item,index) => {
                                    if(item.food_category== 10){

                                    }else{
                                        return(
                                            <li key={index} style={{margin:0}}>
                                            <Form.Group>
                                                <Form.Check type='checkbox' key={index+1} id={`check-api-checkbox-${index+1}`} style={{width:'100%', display:'flex',alignItems:'center',justifyContent:'space-between', padding:'0 1rem'}}>
                                                {/* <Form.Label style={{marginBottom:0}}>{item.food_name}</Form.Label> */}
                                                <Title className="regular text-left" value={item.food_name} />
                                                <Form.Check.Input type='checkbox' isValid
                                                    id={item.food_id}
                                                    style={{position:'relative', margin:0, border:'1px solid rgb(0 0 0 / 40%)', borderRadius:'100%',marginLeft: '8px', padding:'10px'}}
                                                    onChange={(e)=>{
                                                        if (e.target.checked) {
                                                            // 체크된 경우 건강고민 ID를 추가
                                                            setEditFoodList((prev) => [...prev, item.food_id]);
                                                        } else {
                                                            // 체크 해제된 경우 건강고민 ID를 제거
                                                            setEditFoodList((prev) => prev.filter((id) => id !== item.food_id));
                                                        }
                                                    }}
                                                />
                                                </Form.Check>
                                            </Form.Group>
                                            </li>
                                        )
                                    }
                                })}
                            </ul>
                        </DayRecordBox>
                    </RecordWrap>
                    <DayEditBtn>
                        <Btn onClick={handleSave} variant="primary" size="lg" type="submit" className="full primary mt-2" value="저장" />
                        <Btn onClick={()=>setDayEdit(false)} variant="primary" size="lg" type="submit" className="full grayborder" value="취소" />
                    </DayEditBtn>
                </Offcanvas>
                {/* 기본 recordday 내용 */}
                <>
                    <LoHeader className='mt-3 mb-3'>
                        <Title className="medium w_800" style={{cursor:'pointer', padding: '8px 0'}}
                            onClick={()=>navigate('/record')}
                            value={params.number} />
                        <span onClick={()=>navigate(-1)} class="material-symbols-outlined" 
                            style={{cursor:'pointer', position: 'absolute', left:0, padding: '16px 16px 16px 8px'}}>arrow_back</span>
                        {/* <span onClick={()=>navigate(-1)} class="material-symbols-outlined" 
                            style={{cursor:'pointer', position: 'absolute', right:0, padding: '16px 8px 16px 16px'}}>arrow_forward</span> */}
                    </LoHeader>
                    <RecordWrap>
                        <DayRecordBox>
                            <ul>
                                {recordData[currentMonth] && recordData[currentMonth][0]?.map((item,index) => {
                                    if(item.record_date === params.number){
                                        return(
                                            <>
                                            {item.foods?.map((food,key) => (
                                                <li key={index}>
                                                        <Title key={key} className="regular text-left" value={food.food_name} />
                                                </li>
                                            ))}
                                            </>
                                        )
                                    }
                                })}
                            </ul>
                        </DayRecordBox>
                    </RecordWrap>
                    <DayEditBtn onClick={()=>setDayEdit(true)}>
                        <Btn variant="primary" size="lg" type="submit" className="full lightpink mt-5 mb-4" value="기록 추가" />
                    </DayEditBtn>
                    <DayEditBtn onClick={handleDelete}>
                        <Btn variant="primary" size="lg" type="submit" className="full lightgray mb-5" value="기록 삭제" />
                    </DayEditBtn>
                </>
            </Wrapper>
        <Footer />
        </ThemeProvider>
    )
}
export default RecordDay;