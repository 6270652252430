import { BodyWrapper } from "../../atom/Wrapper/Wrapper";
import {useForm} from 'react-hook-form';
import React,{useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { LoginBox, FormBox, ErrBox } from "./Register.styled";
import Btn from "../../atom/Btn/Btn";
import Title from "../../atom/Title/Title";
import axios from "axios";


const PasswordCheck = (props) => {
    const navigate = useNavigate();

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };

    const query = useQuery();
    
    const uidb64 = query.get('uidb64');
    const token = query.get('token');
    console.log(uidb64, token)

    const [authCheck,setAuthToCheck] = useState('');
    const [authReCheck, setAuthToReCheck] = useState('');
    const {
        register,
        getValues,
        formState: {errors},
    } = useForm();

    const PasswordResetDone = async(email) => {
        if(authCheck === ''){
            alert('인증코드를 입력해주세요')
        }else{
            try{
                const response = await axios.post('/user/password_reset/done/', {
                    password: authCheck,
                    repassword: authCheck,
                    uidb64: uidb64,
                    token: token
                },{headers: {
                    "Content-Type": "application/json",}});

                alert('비밀번호가 변경되었습니다!')
                navigate('/login')
            }catch(error){
                alert('네트워크 오류가 발생햇습니다. 카카오@ 뉴지엄으로 문의 부탁드립니다')
            }
        }
    }
    return(
        <BodyWrapper>
            <LoginBox>
                <Title onClick={()=>navigate("/")} className="medium mt-4" value="비밀번호 재설정" />
                {query.size == 0?
                <FormBox>
                    <Title className="light mt-4 mb-4" value="이메일에서 인증페이지로 이동해주세요📨" />

                    <Btn onClick={()=>navigate('/pwreset')}
                            style={{border:'none'}}
                            variant="secondary" size="sm" className="mt-3 lightgray mb-3" value="이메일 재전송" />
                    <div style={{width: '100%', textAlign:'center'}}>
                        <a id="chat-channel-button" href="javascript:chatChannel()" style={{display:'flex',justifyContent:'center', alignItems:'center'}}>
                            문의 : 
                            <img src="https://developers.kakao.com/assets/img/about/logos/channel/consult_small_yellow_pc_2X.png"
                                style={{maxWidth:'80px', margin:0}}
                                alt="카카오톡 채널 채팅하기 버튼" />
                        </a>
                    </div>
                </FormBox>
                :
                    <FormBox>
                        <Form.Group className="mb-4 row-flex">
                        <span class="material-symbols-outlined">lock</span>
                        <Form.Control 
                            {...register('password', {
                                required: '비밀번호를 입력해주세요 :)',
                                minLength: {
                                    value: 4,
                                    message: '😭 비밀번호를 4자 이상 입력해주세요',
                                },
                            })}
                            placeholder='비밀번호' type='password' 
                            onChange={(event)=>setAuthToCheck(event.target.value)}
                            error={errors.password}/>
                        </Form.Group>
                        {errors.password ? (<ErrBox>{errors.password.message}</ErrBox>):null}
                        <Form.Group className="mb-3 row-flex">
                        <span class="material-symbols-outlined none">lock</span>
                        <Form.Control 
                            {...register('password2', {
                                required: '비밀번호를 확인해주세요',
                                minLength: {
                                    value: 4,
                                    message: '😭 비밀번호를 4자 이상 입력해주세요',
                                },
                            })}
                            placeholder='비밀번호 확인' type='password' 
                            onChange={(event)=>setAuthToReCheck(event.target.value)}
                            error={errors.password2}/>
                            </Form.Group>
                            {errors.password2 ? (
                            <ErrBox>{errors.password2.message}</ErrBox>):null}
                        {authCheck != authReCheck?<ErrBox>비밀번호가 일치하지 않습니다</ErrBox>:null}
                        <Btn onClick={()=>PasswordResetDone()} size="lg" type="button" className="mt-5 full pinegreen" value="저장하기" />
                        <Btn onClick={()=>navigate('/login')}
                            style={{border:'none'}}
                            variant="secondary" size="sm" className="grayborder" value="돌아가기" />
                    </FormBox>
                }

            </LoginBox>
        </BodyWrapper>
    )
}
export default PasswordCheck;