import React, { useState, useEffect } from "react";
import { Backdrop, Modal, ModalBox } from "../../atom/Modal/Modal";
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import axios from 'axios';
import {useForm} from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Btn from '../../atom/Btn/Btn';
import { useNavigate, Link } from "react-router-dom";
import { FormBox, ErrBox } from "../Register/Register.styled";
import Header, { LoHeader } from "../../atom/Header/Header";
import Title from "../../atom/Title/Title";
import { AddUserBtn, UserFormBox, HealthBox } from "./User.styled";
import { useSelector } from "react-redux";
import  ReactDOM  from "react-dom";
import { userActions } from "../../../store/user-slice";
import { useDispatch } from "react-redux";
import {basicActions} from '../../../store/basic-slice';


export const AddUser = (props) => {
    const dispatch = useDispatch();
    const userId = useSelector((state)=>state.auth.userId)
    const {
        register,
        handleSubmit,
        formState: {errors},
        setError,
        clearErrors,
    } = useForm();
    const isLoading = useSelector((state) => state.basic.isLoading);

    const navigate = useNavigate();
    // user_gender 선택
    const [usergender, setUserGender] = useState("F");

    const allergylist = useSelector((state) => state.basic.all_allergy); //알러지 전체 리스트

    const [allergyshow, setAllergyShow] = useState(false); //알러지 선택 modal창
    const [user_allergy, setUserAllergy] = useState(['알러지 없음']); //선택한 알러지 이름 리스트
    const [user_check, setUserCheck] = useState(false); //알러지 없음 선택 시 다른 선택지 disabled
    const [user_allergylist, setUserAllergyList] = useState([]);  //선택한 알러지 id값 리스트

    const handleAllergyCheck = () =>{ //선택한 알러지 이름 저장
        setUserAllergy([]);
        user_allergylist?.map((item)=>{
            if(item<1){
                setUserAllergy(['알러지 없음'])
            }else{
                setUserAllergy((prev)=>([...prev, ' ',allergylist[item-1].allergy]))
            }
        })
        setAllergyShow(false)
    }

    //사용자 고민 조회
    const afflictionlist = useSelector((state)=>state.basic.all_affliction); //전체 사용자고민 리스트
    const [user_afflictionlist, setUserAfflictionList] = useState([]);  //선택한 고민 id값 리스트
    const incongruitylist = useSelector((state)=> state.basic.all_incongruity).filter(item => item.incongruity_id === "7" || item.incongruity_id === "8");
    const [user_incongruitylist, setUserIncongruityList] = useState([]);

    //BMI 체크 박스
    const [BMI, checkBMI] = useState(false)
    //BMI 비만 시 설명 변수
    const [BMI_description, setBMIDescription] = useState(0);

    //설문 페이지 분리
    const [nextpage, setNextPage] = useState(0);

    const handleNextPage = (data) =>{
        if(nextpage < 3){
            setNextPage(nextpage + 1);
        }else{
            alert('에러가 발생하였습니다. 다시 접속해주십시오.')
        };
        try{
            let bmi = parseInt(data.user_weight / data.user_height**2 * 10000)
            if (bmi > 25){ //비만 25이상
                checkBMI(true);
                setBMIDescription(bmi);

                let foundItem = afflictionlist.find(item => item.affliction === '비만');
                if(user_afflictionlist.includes(foundItem.affliction_id)){
                }else{
                    setUserAfflictionList((prev) => [...prev, foundItem.affliction_id]);
                }
            };
        }
        catch{} 
    }
    const handlePrePage = () => {
        if(nextpage == 0 && nextpage > 3){
            alert('에러가 발생하였습니다. 다시 접속해주십시오.')
        }else{
            setNextPage(nextpage - 1);
        }
    }

    // 사용자 등록
    const AddUserValid = async({card_nickname, user_birthday, user_gender, user_height, user_weight, user_affliction}) => {
        dispatch(
            basicActions.setisLoading({
                isLoading: true
            }))
                try{
                    const response = await axios.post('/user_info/api/v1/userinfo_save/', {
                        user_id: userId,
                        card_nickname: card_nickname,
                        user_birthday: user_birthday.split("-").join(""),
                        user_gender: usergender,
                        user_height: user_height,
                        user_weight: user_weight,
                        user_allergy_id: user_allergylist,
                        user_incongruity_id: [...user_incongruitylist, ...user_afflictionlist], //(-)기능 등록
                        user_affliction_id: user_afflictionlist, //(+) 고민 등록
                    },
                    {headers: {
                        "Content-Type": "application/json",}});
                    dispatch(
                        userActions.setmainuser({
                            main_user: card_nickname,
                        })
                    )

                    //사용자 추천 푸드 리스트 호출
                    try{
                        const response2 = await axios.post('/food/api/v1/user_recommend_list/', {
                            user_id: userId,
                            user_card: response.data.user_card.id,
                        },
                        {headers: {
                            "Content-Type": "application/json",}});

                        dispatch(
                            userActions.setmainuserid({
                                main_user_id: response.data.user_card.id
                            })
                        );

                        const timeoutExecute = setTimeout(()=>{
                            navigate("/about");
                            window.location.reload();
                        }, 2000);
                        
                    }catch(error){
                        alert('식품 데이터 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
                    }

                }catch(error){
                    if(error.response && error.response.status ===400){
                        alert('잘못 입력되었습니다. 다시 시도해주세요!');
                    }else{
                        alert('오류가 발생했습니다. 담당자에게 문의해주세요!');
                    }
                }finally{
                    dispatch(
                        basicActions.setisLoading({
                            isLoading: false
                        }))
                };
    }

    return(
        <ThemeProvider
        breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
        minBreakpoint="xxs"
        >
            <Header value={<LoHeader><span onClick={()=>navigate(-1)} class="material-symbols-outlined" style={{position:'absolute', left: 0,cursor:'pointer' }}>arrow_back</span> <Title className="medium" value="사용자 추가" /></LoHeader>} />
            {/* 페이지 분리 */}
            <UserFormBox>
                {nextpage === 0?

                    <Form onSubmit={handleSubmit(handleNextPage)}>
                        <section>
                            <Form.Label className="strong mb-4" htmlFor="card_nickname">닉네임(이름 또는 별명)</Form.Label>
                            <Form.Group className="row-flex mb-4">
                                <span class="material-symbols-outlined">person</span>
                                <Form.Control 
                                    {...register('card_nickname', {
                                        required: '닉네임을 입력해주세요 :)',
                                        maxLength: { value: 10, message: '10글자 이하로 입력해주세요.' } // 최대 길이 제한 설정
                                    })}
                                    id="card_nickname"
                                    placeholder='' type='text'
                                    isInvalid={errors.card_nickname} />
                            </Form.Group>
                            {errors.card_nickname ? (
                                <ErrBox>{errors.card_nickname.message}</ErrBox>):null}
                
                            <Form.Label className="strong mb-4" htmlFor="user_birthday">생년월일</Form.Label>
                            <Form.Group className="row-flex mb-4">
                                <span class="material-symbols-outlined">cake</span>
                                <Form.Control 
                                    {...register('user_birthday', {
                                        required: '생년월일을 선택해주세요 :)'
                                    })}
                                    id="user_birthday"
                                    placeholder='생년월일' type='date'
                                    isInvalid={errors.user_birthday} />
                            </Form.Group>
                            {errors.user_birthday ? (
                                <ErrBox>{errors.user_birthday.message}</ErrBox>):null}
                        </section>
                        <AddUserBtn>
                            <Btn variant="primary" size="lg" type="submit" className="full primary mt-4 mb-5" value="다음" />
                        </AddUserBtn>
                    </Form>

                :nextpage === 1?

                    <Form onSubmit={handleSubmit(handleNextPage)}>
                        <Form.Label className="strong mb-4" htmlFor="user_gender">성별</Form.Label>
                            <Form.Group className="row-flex mb-4" id="user_gender">
                                <Form.Control value='여자' type='button'
                                    style={{borderRadius:'2rem'}}
                                    className={usergender==="F"?"gender_checked raio_btn":"raio_btn"}
                                    onClick={()=>{setUserGender("F")}} />
                                <Form.Control value='남자' type='button'
                                    style={{borderRadius:'2rem'}}
                                    className={usergender==="M"?"gender_checked raio_btn":"raio_btn"}
                                    onClick={()=>{setUserGender("M")}} />
                            </Form.Group>
                                
                            <Form.Group className="row-flex mb-4 mt-5" style={{justifyContent:'space-between'}}>
                                <div style={{display:'flex', alignItems:'center'}}>
                                <Form.Label className="strong" htmlFor="user_height" style={{margin: '0 8px 0 0'}}>키</Form.Label>
                                    <Form.Control 
                                        className="sm_width"
                                        {...register('user_height', {
                                            required: '키와 몸무게를 입력해주세요 :)',
                                            pattern: {
                                                value: /^\d+$/,
                                                message: '숫자만 입력하세요',
                                            },
                                        })}
                                        id="user_height"
                                        placeholder='' type='text'
                                        isInvalid={errors.user_height} />
                                    cm
                                </div>
                                <div style={{display:'flex', alignItems:'center'}}>    
                                <Form.Label className="strong" htmlFor="user_weight" style={{margin: '0 8px 0 0'}}>몸무게</Form.Label>
                                    <Form.Control 
                                        className="sm_width"
                                        {...register('user_weight', {
                                            required: '키와 몸무게를 입력해주세요 :)',
                                            pattern: {
                                                value: /^\d+$/,
                                                message: '숫자만 입력하세요',
                                            },
                                        })}
                                        id="user_weight"
                                        placeholder='' type='text'
                                        isInvalid={errors.user_weight} />
                                    kg
                                </div>
                            </Form.Group>
                            {errors.user_height ? (
                                <ErrBox>{errors.user_height.message}</ErrBox>):null}
                        <AddUserBtn>
                            <Btn onClick={()=>{handlePrePage()}} variant="primary" size="sm" type="submit" className="full lightgray mt-4" value="이전으로" />
                            <Btn variant="primary" size="lg" type="submit" className="full primary mt-4 mb-5" value="다음" />
                        </AddUserBtn>
                    </Form>
                    
                : 
                
                    <Form onSubmit={handleSubmit(AddUserValid)}>
                            <Form.Label className="strong mb-2 mt-0"
                                style={{width:'100%', textAlign:'center', fontSize:'1.1rem', fontWeight:500}}>
                                    건강고민</Form.Label>
                            <Title className="detail_center mb-4" value="고민이 있는 부분을 모두 선택해주세요" />
                        <HealthBox> {/* 건강고민 box */}
                            {/* 알러지 중복체크 */}
                            <div onClick={()=>setAllergyShow(()=>{
                                    // Body scroll hidden 추가(backdrop이 스크롤되지 않도록)
                                    document.body.style.overflow = "hidden";
                                    return true
                                }
                                )} style={{padding:'0 1rem'}}>
                                <Form.Label className="strong" htmlFor="user_allergy">알러지</Form.Label>
                                <Form.Group className="row-flex mb-2">
                                    <Form.Select style={{backgroundColor:'white', color: 'gray',pointerEvents:'none'}}>
                                        <option required value={"0"}>{user_allergy}</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            {allergyshow?
                                <>
                                {ReactDOM.createPortal(
                                   <Backdrop />,document.getElementById('backdrop-root') 
                                )}
                                {ReactDOM.createPortal(
                                    <Modal>
                                        <ModalBox>
                                        <Title className="medium mb-4" value="알러지" />
                                            <div style={{height:'50vh', overflowY:'scroll'}}>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    id={'none'}
                                                    label={`알러지 없음`}
                                                    checked={user_check}
                                                    onChange={(e)=>{
                                                        if(e.target.checked){
                                                            setUserCheck(true);
                                                            setUserAllergyList([0]);
                                                        }else{
                                                            setUserCheck(false);
                                                            setUserAllergyList([]);
                                                        }
                                                    }}
                                                />
                                                {allergylist&&allergylist.length > 0 ?
                                                (allergylist?.map((allergy,index)=>(
                                                    <Form.Check type='checkbox' key={index+1} id={`check-api-checkbox-${index+1}`}>
                                                        <Form.Check.Input type='checkbox' isValid
                                                            style={{border: '1px solid #dee2e6'}}
                                                            disabled={user_check}
                                                            checked={user_check? false :user_allergylist.includes(allergy.allergy_id)}
                                                            onChange={(e)=>{
                                                                const allergyId = allergy.allergy_id;
                                                                if (e.target.checked) {
                                                                    // 체크된 경우 알러지 ID를 추가
                                                                    setUserAllergyList((prev) => [...prev, allergyId]);
                                                                } else {
                                                                    // 체크 해제된 경우 알러지 ID를 제거
                                                                    setUserAllergyList((prev) => prev.filter((id) => id !== allergyId));
                                                                }
                                                            }}
                                                        />
                                                        <Form.Check.Label>{allergy.allergy}</Form.Check.Label>
                                                    </Form.Check>
                                                )))
                                                :null}
                                        </div>
                                        <Btn onClick={()=>{
                                            // Body scroll hidden 제거
                                            document.body.style.overflow = "unset";
                                            return handleAllergyCheck()
                                        }} variant="primary" size="lg" type="button" className="full primary mt-4" value="알러지 저장" />
                                        </ModalBox>
                                    </Modal>,document.getElementById('modal-root') 
                                )}
                                </>
                            :null}

                            {/* 사용자 고민 */}
                            <Form.Group className="column-flex mb-2" style={{alignItems:'flex-start'}}>
                                {afflictionlist?.map((affliction, index)=>(
                                    <Form.Check type='checkbox' key={index+1} id={`check-api-checkbox-${index+1}`} style={{width:'100%', display:'flex',alignItems:'center',justifyContent:'space-between', borderTop:'1px solid #eee', padding:'0 1rem'}}>
                                        <Form.Label className="strong" style={{marginBottom: '1rem'}}>{affliction.affliction}</Form.Label>
                                        {/* 비만 권장 메모 */}
                                        {affliction.affliction =='비만' && BMI_description != 0 ?<span style={{fontSize:'12px', margin:'0 8px', color: 'orangered'}}> 권장 / BMI {BMI_description}(비만) </span> :null}
                                        <Form.Check.Input type='checkbox' isValid
                                            id={affliction.affliction}
                                            checked={affliction.affliction == '비만' && BMI? true:null}
                                            style={{position:'relative', margin:0, border:'1px solid #3C3C3C', marginLeft: '8px'}}
                                            onChange={(e)=>{
                                                if(e.target.id=='비만'){
                                                    checkBMI(!BMI)
                                                }
                                                if (e.target.checked) {
                                                    // 체크된 경우 건강고민 ID를 추가
                                                    setUserAfflictionList((prev) => [...prev, affliction.affliction_id]);
                                                } else {
                                                    // 체크 해제된 경우 건강고민 ID를 제거
                                                    setUserAfflictionList((prev) => prev.filter((id) => id !== affliction.affliction_id));
                                                }
                                            }}
                                        />
                                    </Form.Check>
                                ))}
                                {incongruitylist?.map((incon,index)=>(
                                    <Form.Check type='checkbox' key={index+1} id={`check-api-checkbox-${index+1}`} style={{width:'100%', display:'flex',alignItems:'center',justifyContent:'space-between', borderTop:'1px solid #eee', padding:'0 1rem'}}>
                                    <Form.Label className="strong" style={{marginBottom: '1rem'}}>{incon.incongruity}</Form.Label>
                                    <Form.Check.Input type='checkbox' isValid
                                        id={incon.incongruity}
                                        style={{position:'relative', margin:0, border:'1px solid #3C3C3C', marginLeft: '8px'}}
                                        onChange={(e)=>{
                                            if (e.target.checked) {
                                                // 체크된 경우 건강고민 ID를 추가
                                                setUserIncongruityList((prev) => [...prev, incon.incongruity_id]);
                                            } else {
                                                // 체크 해제된 경우 건강고민 ID를 제거
                                                setUserIncongruityList((prev) => prev.filter((id) => id !== incon.incongruity_id));
                                            }
                                        }}
                                    />
                                    </Form.Check>
                                ))

                                }
                            </Form.Group>
                        </HealthBox>
                            <AddUserBtn>
                                <Btn onClick={()=>{handlePrePage()}} variant="primary" size="sm" type="submit" className="full lightgray mt-4" value="이전으로" />
                                <Btn variant="primary" size="lg" type="submit" className="full primary mt-4 mb-5" value="저장" />
                            </AddUserBtn>
                    </Form>
                }
            </UserFormBox>
        </ThemeProvider>
    )
}
