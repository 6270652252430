import React,{useState, useEffect} from "react";
import {useForm} from 'react-hook-form';
import axios from 'axios';
import {Link, Navigate, useNavigate, useSearchParams} from "react-router-dom";
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { LoginBox, FormBox, ErrBox } from "./Register.styled";
import Header from "../../atom/Header/Header";
import Btn from "../../atom/Btn/Btn";
import Title from "../../atom/Title/Title";
import { BfHeader } from "../../atom/Header/Header";
import {useDispatch, useSelector} from 'react-redux';
import { authActions } from "../../../store/auth-slice";
import { userActions } from "../../../store/user-slice";
import logo from '../../lib/img/nuseum+logo.png';
import Wrapper from "../../atom/Wrapper/Wrapper";
import KakaoLogin from "./SNSLogin";

const LogIn = (props) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        //로컬스토리지 확인
        const token = localStorage.getItem('authToken');

        if(token!==null){
            navigate('/')
        }
        // window.sessionStorage.removeItem('isLoggedIn');
    }, []);

    const {
        register,
        handleSubmit,
        formState: {errors},
        setError,
        clearErrors,
    } = useForm();


    axios.defaults.withCredentials = true;
    const LoginValid = async({user_id, password}) => {
        try{
            setIsLoading(true);
            // AbortController를 생성하고 타임아웃을 30초로 설정합니다.
            const controller1 = new AbortController();
            const timeoutId1 = setTimeout(() => controller1.abort(), 30000);

            const response = await axios.post('/user/auth/', {
                    user_id: user_id,
                    password: password,
                },
                {headers: {
                        "Content-Type": "application/json",},
                    signal: controller1.signal, // AbortController를 fetch에 전달합니다.
                });
            // 타임아웃 전에 응답이 왔다면 clearTimeout을 호출하여 타임아웃을 취소합니다.
            clearTimeout(timeoutId1);

            if (response.status >= 200 &&response.status < 300 ) {
                // 성공적인 응답 처리
            } else {
                throw new Error(`HTTP error! Status: ${response.status}`);}

            loginProcess(user_id, response.data.code, response.data.token.refresh)
        }catch(error){
            if(error.response && error.response.status === 400){
                alert('아이디 혹은 비밀번호가 틀렸습니다')
            }else{
                alert('오류가 발생했습니다. 담당자에게 문의해주세요!');
            }
        }finally {
            setIsLoading(false);
        }
    };

    const loginProcess = (user_id, res_code, token_refresh) => {
        if (res_code==='0000') {
            // localstorage저장
            localStorage.clear();
            localStorage.setItem('initialized', 'true');
            localStorage.setItem('id',user_id);
            localStorage.setItem('authToken',token_refresh);
            const expirationDate = new Date(new Date().getTime() + 432000); // 만료 날짜 계산
            localStorage.setItem('expirationDate', expirationDate.toISOString()); // ISO 형식으로 저장

            dispatch(
                authActions.login({
                    token: token_refresh,
                    userId: user_id,
                })
            )
            navigate('/');
            window.location.reload();
        } else {
            alert('오류가 발생했습니다. 담당자에게 문의');
        }
    }


    // 카카오톡 로그인 로직
    useEffect(() => {
        if (searchParams.size && searchParams.size > 0) {
            loginWithKakaoCallback();
        }
    }, [searchParams, setSearchParams]);
    const loginWithKakaoCallback = async() => {
        const response = await axios.get('/user/kakao/callback/?' + searchParams.toString());
        loginProcess(response.data.user.user_id, response.data.code, response.data.token.refresh);
    }

    return(
        <ThemeProvider
            breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
            minBreakpoint="xxs"
        >
            <LoginBox>
                <div className="logo_img"  onClick={()=>navigate("/")}>
                    <img src={logo} alt='뉴지엄 로고' />
                </div>
                <Title onClick={()=>navigate("/")} className="big_logo" value="뉴지엄" />
                <FormBox>
                    <Form onSubmit={handleSubmit(LoginValid)}>

                        <Form.Group className="mb-3 row-flex">
                            <span className="material-symbols-outlined">person</span>
                            <Form.Control
                                {...register('user_id', {
                                    required: '아이디를 입력해주세요 :)'
                                })}
                                placeholder='아이디' type='text'
                                isInvalid={errors.user_id} />
                        </Form.Group>
                        {errors.user_id ? (
                            <ErrBox>{errors.user_id.message}</ErrBox>):null}

                        <Form.Group className="mb-3 row-flex">
                            <span className="material-symbols-outlined">lock</span>
                            <Form.Control
                                {...register('password', {
                                    required: '비밀번호를 입력해주세요 :)',
                                    minLength: {
                                        value: 4,
                                        message: '😭 비밀번호를 4자 이상 입력해주세요',
                                    },
                                })}
                                placeholder='비밀번호' type='password'
                                error={errors.password}/>
                        </Form.Group>
                        {errors.password ? (
                            <ErrBox>{errors.password.message}</ErrBox>):null}

                        {isLoading ? (
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        ) : (
                            <Btn variant="primary" size="lg" type="submit" className="full pinegreen mt-5" value="로그인" />
                        )}
                        <Link to='/signup'>
                            <Btn variant="secondary" size="lg" className="full primary" value="회원가입" />
                        </Link>

                        <KakaoLogin />

                        <div style={{display: 'flex', justifyContent:'space-between', alignItems:'center'}}>
                            <Btn onClick={()=>navigate('/pwreset')}
                                 variant="secondary" size="sm" className="lightgray full" value="비밀번호 찾기" />
                            <Btn onClick={()=>navigate('/')}
                                 variant="secondary" size="sm" className="lightgray full" value="돌아가기" />
                        </div>
                    </Form>
                </FormBox>
            </LoginBox>
        </ThemeProvider>

    )
}
export default LogIn;
