import { BodyWrapper, FlexBox } from '../../atom/Wrapper/Wrapper';
import Header,{ LoHeader } from '../../atom/Header/Header';
import Title from '../../atom/Title/Title';
import { BfHeader } from '../../atom/Header/Header';
import { useNavigate } from 'react-router-dom';
import Wrapper from '../../atom/Wrapper/Wrapper';
import UserList from '../User/UserList';
import {useSelector, useDispatch } from 'react-redux';
import BasketFood from './Basket/BasketFood';
import home04 from '../../lib/img/home04.jpg';
import { useState } from 'react';
import Btn from '../../atom/Btn/Btn';
import { HomeImgSection } from './Home.styled';
import ReactDOM from 'react-dom';
import { Backdrop, Modal, ModalBox } from '../../atom/Modal/Modal';
import blog from '../../lib/img/sns/blog.png';
import facebook from '../../lib/img/sns/facebook.png';
import insta from '../../lib/img/sns/insta.png';
import linkedin from '../../lib/img/sns/linkedin.png';
import axios from 'axios';
import { authActions } from '../../../store/auth-slice';
import Terms from './Terms';

// 로그인 전 home
export const BfHome = () =>{
    const [noticeshow, setNoticeShow] = useState(true);
    const navigate = useNavigate();
    const sns_img = [blog, facebook, insta, linkedin];
    const sns_url = ['https://blog.naver.com/nuseum-lab','https://m.facebook.com/profile.php?id=61552160438864&_rdr','https://www.instagram.com/nuseum_official/','https://www.linkedin.com/company/nuseum-lab'];
    const [terms, setTerms] = useState(false);
    
    return(
    <BodyWrapper bg="homebg">
        <Header style={{background: '#01716D', border:'none'}} value={<BfHeader><Title onClick={()=>navigate("/")} className="big_logo white" value="뉴지엄랩" />
        <div>
            <span style={{color:'white'}} onClick={()=>setNoticeShow(true)} class="material-symbols-outlined">notifications</span>
            <span style={{color:'white'}} onClick={()=>navigate("/login")} className="material-symbols-outlined ml-3">person</span>
        </div>
        </BfHeader>} />
            {/* 공지모달창 */}
            {noticeshow?
            <>
                {ReactDOM.createPortal(
                    <Backdrop />,document.getElementById('backdrop-root') 
                )}
                {ReactDOM.createPortal(
                    <Modal>
                        <ModalBox className='p-32' style={{maxWidth:'480px', width:'95%'}}>
                        <Title className="bold mb-4" value="NOTICE" />
                        <div style={{height:'52vh', overflowY:'auto', display:'flex', flexDirection:'column',justifyContent:'center'}}>
                            {/* <Title className="regular mb-3 text-left" value="뉴지엄 마켓은 4월 10일부터 월구독을 신청하신 분들을 대상으로 뉴지엄 마켓을 제공합니다." />  */}
                            <Title className="regular mb-3 text-left" value="뉴지엄 마켓은 고혈압, 당뇨와 같은 만성 질환의 관리 뿐만 아니라, 자녀의 키 성장과 신경 행동 발달에 관심 있는 부모, 식품 알러지나 유당 불내증, 과민성장증후군을 겪는 이들, 건강한 식습관을 원하는 모든 이들을 위한 영양생리약리 지식 기반 맞춤형 헬스케어 마켓을 제공합니다." /> 
                            <Title className="regular mb-3 text-left" value="아직 월 구독을 신청하지 않으셨다면, 지금 nuseum-lab.com을 통해 뉴지엄 마켓 월구독 신청 후 참여해 주시길 부탁드립니다." />
                        </div>
                        <Btn onClick={()=>{
                            // Body scroll hidden 제거
                            document.body.style.overflow = "unset";
                            setNoticeShow(false);
                        }} variant="primary" size="lg" type="button" className="full pinegreen mt-4 " value="닫기" />
                        </ModalBox>
                    </Modal>,document.getElementById('modal-root') 
                )}
            </>
            :null}
            {/* <section style={{background: 'black'}}>
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/kbbvn34Bl8I?si=sSXYB-T8AAUV-CWV&amp;controls=0&autoplay=1&mute=1&loop=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
                <Btn variant="primary" style={{margin: 0, borderRadius: 0, boxShadow: 'none', background: 'black'}}
                size="lg" onClick={()=>(navigate('/login'))} className="full primary" value="시작하기" />
            </section> */}
            <section className="pt-5">
                <Wrapper>
                    <Title className="bold mb-2 text-left" value="뉴지엄 마켓" /> 
                    <Title className="medium mb-4 text-left" value="서울대학교 영양생리약리연구실의 연구를 실용적인 소비자 솔루션으로 직접 적용합니다." /> 
                    <Title className="regular mb-3 text-left" value="이 플랫폼은 다양한 건강 상태를 가진 개인이나 가족의 식이 및 영양 요구를 충족시키도록 맞춤화 되었습니다. 증거 기반의 건강 지향적 제품을 제공하며, 각 제품은 뉴지엄랩의 철저한 과학 연구에 기반하여 선별됩니다." /> 
                    <Title className="regular mb-3 text-left" value="이는 영양생리약리학적 표준을 충족할 뿐만 아니라 특정 건강 상태를 관리하거나 개선하는 데 직접적으로 기여합니다." /> 
                    <Title className="regular mb-3 text-left" value="뉴지엄 마켓은 맞춤형 식품/제품 데이터베이스와 알고리즘, 맞춤형 건강 & 영양 교육 자료, 선별된 제품에 대한 품질, 고객의 서비스에 대한 접근성을 중요시하며, 과학적 엄격함과 실용적인 건강 관리 필요성을 통합한 종합 서비스를 제공합니다." />
                    <FlexBox className='row_center mt-4' style={{justifyContent:'flex-start', flexWrap:'wrap'}}>
                        <FlexBox box={{display: 'flex'}}>
                            <FlexBox className='row_center mt-4' style={{justifyContent:'flex-start'}}>
                                <Btn onClick={()=>window.open('https://www.nuseum-lab.com/','_blank')}variant="secondary" size="sm" className="grayborder m-2" box={{display: 'flex'}} value="홈페이지" />
                                <Btn onClick={()=>window.open('https://www.jiyoungkimlab.com/','_blank')}variant="secondary" size="sm" className="grayborder m-2" box={{display: 'flex'}} value="CEO 블로그" />
                            </FlexBox>
                            <FlexBox className='row_center mt-4' style={{justifyContent:'flex-center'}}>
                                {sns_img?.map((item,index) => (
                                    <Btn onClick={()=>window.open(sns_url[index],'_blank')}variant="secondary" size="sm" className="grayborder mb-2 border-no" box={{display: 'flex'}} style={{minWidth:'80px'}} value={<img style={{maxWidth:'50px', maxHeight:'30px'}} src={item} alt='' />} />
                                ))}
                            </FlexBox>
                        </FlexBox>
                        <FlexBox className='row_center mt-4' style={{flexGrow: '1', justifyContent:'center'}}>
                            <Btn variant="primary lightsalmon" style={{width: 150, height: 150, margin: 0, borderRadius: 100}}
                                 size="lg" onClick={()=>(navigate('/login'))} className="full primary" value="시작하기" />
                        </FlexBox>
                    </FlexBox>
                </Wrapper>
            </section>
            <HomeImgSection>
                <div className='other' style={{background: '#27322d'}}>
                    <img src={home04} alt='' />
                </div>
            </HomeImgSection>
            <Btn variant="primary" style={{margin: 0, borderRadius: 0}}
                size="lg" onClick={()=>(navigate('/login'))} className="full primary" value="시작하기" />
            <section className="m-3" style={{display:'flex', flexDirection:'column'}}>
                <Title className="regular mt-2" value="뉴지엄랩(NUSEUM Inc.) | 대표: 김지영" />
                <Title className="regular" value="주소: 서울특별시 관악구 호암로24길 6, 3층 306호 | 사업자등록번호: 379-87-02832 | 통신판매: 제 2023-서울관악-2389호" />
                <Title className="light mt-2" value="전화번호 : 01091161927 | 이메일 : nuseum@nuseum-lab.com" />
                <Title style={{cursor:'pointer'}} onClick={()=>setTerms(!terms)} className="light" value="이용약관 | 개인정보취급방침" />
                {terms?
                    <Terms />
                :null}
                <Title className="regular mB-2" value="ⓒAll Rights Reserved" />
            </section>
    </BodyWrapper>
    )
}

//로그인 후 home
export const Home = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const UserCards = useSelector((state)=>state.user.card);
    const user_id = localStorage.getItem('id');

    const handleLogout = async() => {
        try{
            const response = await axios.delete('/user/auth/', {
                user_id: user_id
            },
            {headers: {
                "Content-Type": "application/json",}});

            if(response.data.code==='0000'){
                // window.sessionStorage.removeItem('isLoggedIn');
                // window.sessionStorage.removeItem('userId');
                localStorage.clear();
                dispatch(
                    authActions.logout(),
                )
                
                navigate('/');
            }
        }catch(error){
                alert('오류가 발생했습니다. 담당자에게 문의해주세요!');
            }
        };

    return(
        UserCards===null || UserCards === ''? //  사용자 존재여부
            //사용자 none이라면 사용자 생성 컴포넌트
            <BodyWrapper bg="homebg">
                <Header value={<LoHeader style={{justifyContent:'space-between'}}><Title onClick={()=>navigate("/")} className="logo" value="뉴지엄랩" />
                    <span onClick={()=>handleLogout()} style={{paddingRight:'16px'}} className="material-symbols-outlined">exit_to_app</span></LoHeader>} />
                <UserList />
            </BodyWrapper>

        :
        //이미 사용자가 있다면 개인맞춤장바구니
        <BasketFood />

    );
};
// export default Home;