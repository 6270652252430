import Wrapper from "../../atom/Wrapper/Wrapper";
import { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';
import { ErrBox } from "../Register/Register.styled";
import { HealthBox } from "./User.styled";
import  ReactDOM  from "react-dom";
import { Backdrop, Modal, ModalBox } from "../../atom/Modal/Modal";
import {useForm} from 'react-hook-form';
import Title from "../../atom/Title/Title";
import Btn from '../../atom/Btn/Btn';
import { useSelector } from "react-redux";
import { CardBox, CardBoxTitle,CardImg, CardBoxText } from './Mypage/Mypage.styled'; 
import Card from 'react-bootstrap/Card';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userActions } from '../../../store/user-slice';
import logo_profile from '../../lib/img/nuseum+logo.png';
import { basicActions } from "../../../store/basic-slice";

const UserEdit = (props) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const main_user_id = useSelector((state) => state.user.main_user_id);

    // 스토어에서 user_cards
    const UserCards = useSelector((state)=> state.user.card);
    let UserCardsArray = UserCards?JSON.parse(UserCards):null;

    const {
        register,
        handleSubmit,
        formState: {errors},
        setError,
        clearErrors,
    } = useForm();
    const allergylist = useSelector((state) => state.basic.all_allergy); //알러지 전체 리스트

    const [allergyshow, setAllergyShow] = useState(false); //알러지 선택 modal창
    const [user_allergy, setUserAllergy] = useState(['알러지 없음']); //선택한 알러지 이름 리스트
    const [user_check, setUserCheck] = useState(false); //알러지 없음 선택 시 다른 선택지 disabled
    const [user_allergylist, setUserAllergyList] = useState([]);  //선택한 알러지 id값 리스트
    const handleAllergyCheck = () =>{ //선택한 알러지 이름 저장
        setUserAllergy([]);
        user_allergylist?.map((item)=>{
            if(item<1){
                setUserAllergy(['알러지 없음'])
            }else{
                setUserAllergy(prev => prev.includes(allergylist[item - 1].allergy.trim()) ? prev : [...prev, allergylist[item - 1].allergy.trim()]);
            }
        })
        setAllergyShow(false)
    }
    //사용자 고민 조회
    const afflictionlist = useSelector((state)=>state.basic.all_affliction); //전체 사용자고민 리스트
    const [user_afflictionlist, setUserAfflictionList] = useState([]);  //선택한 고민 id값 리스트
    const incongruitylist = useSelector((state)=> state.basic.all_incongruity).filter(item => item.incongruity_id === "7" || item.incongruity_id === "8");
    const [user_incongruitylist, setUserIncongruityList] = useState([]);

    // 사용자 정보 리스트로 저장(user update시 affliction-allergy-incongruity의 값만 다르면 추천식품 리스트를 다시 불러오도록)
    const [useredit_aff, setUserEditAff] = useState([]);
    const [useredit_allergy, setUserEditAllergy] = useState([]);
    const [useredit_incon, setUserEditIncon] = useState([]);

    // 오프캔버스
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);
    const options = [
        {
          name: 'Enable backdrop (default)',
          scroll: false,
          backdrop: true,
        }]

    // user_gender 선택
    const [usergender, setUserGender] = useState('F');

    //BMI 체크 박스
    const [BMI, checkBMI] = useState(false);
    //BMI 비만 시 설명 변수
    const [BMI_description, setBMIDescription] = useState(0);
    
    // BMI 계산을 위한 키/몸무게 수집
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    // 입력 필드의 값이 변경될 때 몸무게 & 키를 트래킹하도록 호출 함수 추가
    const handleWeightChange = (e) => {
        setWeight(e.target.value);
    };
    const handleHeightChange = (e) =>{
        setHeight(e.target.value);
    };
    // 초기 값 설정
    useEffect(()=>{
        if (props.show == false){
            setUserGender('F')
            setWeight('')
            setHeight('')
            setUserAfflictionList([])
            setUserIncongruityList([])
            setUserAllergy([])
            setUserAllergyList([])
        }else{
            setUserGender(props.useredit.user_gender);
            setWeight(props.useredit.user_weight);
            setHeight(props.useredit.user_height);
            
            props.useredit.user_affliction?.map((u_aff,index) => {
                setUserAfflictionList((prev) => [...new Set([...prev, u_aff.affliction_id])]);
                setUserEditAff((prev) => [...new Set([...prev, u_aff.affliction_id])]);
            });
            props.useredit.user_incongruity?.map((u_incon) => {
                setUserEditIncon((prev) => [...new Set([...prev, u_incon.incongruity_id])]);
                if(u_incon.incongruity_id==='7' || u_incon.incongruity_id==='8'){
                    setUserIncongruityList((prev) => [...new Set([...prev, u_incon.incongruity_id])]);
                }
            });
    
            props.useredit.user_allergy?.map((u_allergy,index)=>{
                setUserEditAllergy((prev)=>[...new Set([...prev, u_allergy.allergy_id])]);
                setUserAllergy((prev) => [...new Set([...prev, u_allergy.allergy])]);
                setUserAllergyList((prev)=>[...new Set([...prev, u_allergy.allergy_id])]);
                });
        }
    },[props.useredit, show]);


    // BMI계산
    useEffect(()=>{
        try{
            let bmi = parseInt(weight / height**2 * 10000)
            if (bmi >= 25){ //비만 25이상
                checkBMI(true);
                setBMIDescription(bmi);
            }else{
                checkBMI(false);
                setBMIDescription(0)
            }

        }
        catch{} 
    },[weight, height])

    // 사용자 업데이트(수정) API전송
    const UserEditValid = async({card_nickname, user_birthday, user_height, user_weight}) => {
        dispatch(
            basicActions.setisLoading({
                isLoading: true
            }))
        const combinedList = [...user_incongruitylist, ...user_afflictionlist];
        try{
            const response = await axios.post(`/user_info/api/v1/userinfo_edit/${props.useredit.id}/`, {
                user_id: id,
                card_nickname: card_nickname,
                user_birthday: user_birthday,
                user_gender: usergender,
                user_height: user_height,
                user_weight: user_weight,
                user_allergy_id: user_allergylist,
                user_incongruity_id: [...new Set(combinedList)], //(-)기능 등록
                user_affliction_id: user_afflictionlist, //(+) 고민 등록
            },
            {headers: {
                "Content-Type": "application/json",}});
            dispatch(
                userActions.setmainuser({
                    main_user: card_nickname,
                })
            )
            dispatch(
                userActions.setuseredit({
                    useredit : true
                })
            )

            if (user_allergylist.toString() != useredit_allergy.toString() || user_afflictionlist.toString() != useredit_aff.toString() || [...new Set(combinedList)].toString() != useredit_incon.toString()){
                //사용자 추천 푸드 리스트 호출 - allergy, incongruity, affliction 값이 변했을 때만 호출
                try{
                    const response2 = await axios.post('/food/api/v1/user_recommend_list/', {
                        user_id: id,
                        user_card: props.useredit.id,
                    },
                    {headers: {
                        "Content-Type": "application/json",}});

                    dispatch(
                        userActions.setmainuserid({
                            main_user_id: props.useredit.id
                        })
                    );
                    localStorage.removeItem('myproductData');
                    localStorage.removeItem('myFoodData');
                    
                }catch(error){
                    alert('식품 데이터 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
                }
            }

            setShow(false);
            window.location.reload();
        }catch(error){
            if(error.response && error.response.status ===400){
                alert('잘못 입력되었습니다. 다시 시도해주세요!')
            }else{
                alert('오류가 발생했습니다? 담당자에게 문의해주세요!');
            }
        }finally{
            dispatch(
                basicActions.setisLoading({
                    isLoading: false
                }))
        }
    };

    // 사용자 카드 삭제 API전송
    const UserDeleteValid = async() => {
        const result = window.confirm("정말로 삭제하시겠습니까?");
        if (result) {
            // 삭제 동작 수행
            console.log("삭제되었습니다.");
            try{
                const response = await axios.post(`/user_info/api/v1/userinfo_delete/${main_user_id}/`, {
                    user_id: id,
                },
                {headers: {
                    "Content-Type": "application/json",}});
                dispatch(
                    userActions.setmainuser({
                        main_user: '',
                    })
                )
                dispatch(
                    userActions.setmainuserid({
                        main_user_id: 0
                    })
                )
                setShow(false);
                navigate('/');
                window.location.reload();
            }catch(error){
                if(error.response && error.response.status ===400){
                    alert('잘못 입력되었습니다. 다시 시도해주세요!')
                }else{
                    alert('오류가 발생했습니다. 담당자에게 문의해주세요!');
                }
            };
        } else {
            // 취소 동작 수행
            console.log("삭제가 취소되었습니다.");
        }
    };

    // return(
    //     <>
    //     {/* offcanvas */}
    //     <Offcanvas show={props.show} onHide={props.onHide} placement={props.placement} style={{minHeight:'100vh', overflowY:'auto'}}>
    //         <Offcanvas.Header closeButton>
    //             <Offcanvas.Title>사용자 수정</Offcanvas.Title>
    //         </Offcanvas.Header>
    //         <Wrapper>
    //             <Title className="regular m-3 mb-5 mt-5" value={`현재는 사용자 정보를 수정하는 기능이 제공되지 않고 있으며 삭제만 가능합니다.${'\n'}빠른 시일 내에 수정하여 더 나은 서비스를 제공할 수 있도록 하겠습니다.`} />
    //             <Btn onClick={()=>{UserDeleteValid()}} variant="primary" size="lg" className="full lightgray mt-4" value="삭제" />
    //         </Wrapper>
    //     </Offcanvas>
    //     </>
    // )

    return(
        <>
        {/* offcanvas */}
        <Offcanvas show={props.show} onHide={props.onHide} placement={props.placement} style={{minHeight:'100vh', overflowY:'auto'}}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>사용자 수정</Offcanvas.Title>
            </Offcanvas.Header>
            <Wrapper style={{maxWidth:'500px', margin:'0 auto'}}>
            <Form onSubmit={handleSubmit(UserEditValid)}>
                <Offcanvas.Body>
                    <CardBoxTitle style={{border:'none'}}>
                        <CardImg src={logo_profile} alt='사용자' />
                        <Card.Text>
                            {/* // 카드 닉네임 INPUT */}
                            <Form.Group className="row-flex">
                                <Form.Control 
                                {...register('card_nickname',{
                                    required: '닉네임을 입력해주세요 :)'
                                })}
                                    defaultValue={props.useredit.card_nickname}
                                    id="card_nickname"
                                    placeholder={props.useredit.card_nickname} type='text' />
                            </Form.Group>
                        </Card.Text>
                    </CardBoxTitle>

                    {/* // 키와 몸무게 INPUT */}
                    <Form.Group className="row-flex mb-4" style={{justifyContent:'space-between', display: 'flex', flexWrap:'wrap', maxWidth:'600px', margin: '0 auto'}}>
                        <div className="mr-3 mb-3" style={{display:'flex', alignItems:'center'}}>
                        <Form.Label className="strong" htmlFor="user_height" style={{margin: '0 8px 0 0', minWidth:'43px'}}>키</Form.Label>
                            <Form.Control 
                                style={{width: '50%', minWidth:'100px'}}
                                className="sm_width"
                                {...register('user_height', {
                                    required: '키와 몸무게를 입력해주세요 :)',
                                    pattern: {
                                        value: /^\d+$/,
                                        message: '숫자만 입력하세요',
                                    },
                                })}
                                defaultValue={props.useredit.user_height}
                                onChange={handleHeightChange}
                                id="user_height"
                                placeholder={props.useredit.user_height} type='text'
                                isInvalid={errors.user_height} />
                            <span className="ml-2">cm</span>
                        </div>
                        <div className="mb-3" style={{display:'flex', alignItems:'center'}}>    
                        <Form.Label className="strong" htmlFor="user_weight" style={{margin: '0 8px 0 0', minWidth:'43px'}}>몸무게</Form.Label>
                            <Form.Control 
                                style={{width: '50%', minWidth:'100px'}}
                                className="sm_width"
                                {...register('user_weight', {
                                    required: '키와 몸무게를 입력해주세요 :)',
                                    pattern: {
                                        value: /^\d+$/,
                                        message: '숫자만 입력하세요',
                                    },
                                })}
                                defaultValue={props.useredit.user_weight}
                                onChange={handleWeightChange} // 입력이 변경될 때 호출되는 함수, BMI 설명을 보여주고자 추가
                                id="user_weight"
                                placeholder={props.useredit.user_weight} type='text'
                                isInvalid={errors.user_weight} />
                            <span className="ml-2">kg</span>
                        </div>
                    </Form.Group>
                    {errors.user_height ? (
                    <ErrBox>{errors.user_height.message}</ErrBox>):null}
                    {/* // 성별 선택 버튼 */}
                    <Form.Label className="strong mb-4" htmlFor="user_gender">성별</Form.Label>
                        <Form.Group className="mb-4" id="user_gender" style={{display:'flex'}}>
                            <Form.Control value='여자' type='button'
                                style={{borderRadius:'2rem', background:usergender==="F"?'rgba(255, 206, 86, 0.5)':'white'}}
                                onClick={()=>{setUserGender("F")}} />
                            <Form.Control value='남자' type='button'
                                style={{borderRadius:'2rem', background:usergender==="M"?'rgba(255, 206, 86, 0.5)':'white'}}
                                onClick={()=>{setUserGender("M")}} />
                        </Form.Group>


                    <Form.Group className="row-flex mb-4 mt-5">
                        <Form.Label className="strong mb-2 mt-0"
                            style={{width:'100%', textAlign:'center', fontSize:'1.1rem', fontWeight:500}}>
                                건강고민</Form.Label>
                        <HealthBox className="mb-3"> {/* 건강고민 box */}
                            {/* 알러지 중복체크 */}
                            <div onClick={()=>setAllergyShow(()=>{
                                // Body scroll hidden 추가(backdrop이 스크롤되지 않도록)
                                document.body.style.overflow = "hidden";
                                return true
                            })} style={{padding:'0 1rem'}}>
                                <Form.Label className="strong" htmlFor="user_allergy">알러지</Form.Label>
                                    <Form.Group className="row-flex mb-2">
                                        <Form.Select style={{backgroundColor:'white', color: 'gray',pointerEvents:'none'}}>
                                            <option required value={"0"}>{user_allergy}</option>
                                        </Form.Select>
                                    </Form.Group>
                            </div>
                            {/* 알러지 모달창 띄우기 */}
                            {allergyshow?
                                <>
                                {ReactDOM.createPortal(
                                    <Backdrop />,document.getElementById('backdrop-root') 
                                    )}
                                    {ReactDOM.createPortal(
                                        <Modal>
                                            <ModalBox>
                                            <Title className="medium mb-4" value="알러지" />
                                                <div style={{height:'50vh', overflowY:'scroll'}}>
                                                    <Form.Check // prettier-ignore
                                                        type='checkbox'
                                                        id={'none'}
                                                        label={`알러지 없음`}
                                                        checked={user_check}
                                                        onChange={(e)=>{
                                                            if(e.target.checked){
                                                                setUserCheck(true);
                                                                setUserAllergyList([0]);
                                                            }else{
                                                                setUserCheck(false);
                                                                setUserAllergyList([]);
                                                            }
                                                        }}
                                                    />
                                                    {allergylist&&allergylist.length > 0 ?
                                                    (allergylist?.map((allergy,index)=>(
                                                        <Form.Check type='checkbox' key={index+1} id={`check-api-checkbox-${index+1}`}>
                                                                <Form.Check.Input type='checkbox' isValid
                                                                    style={{border: '1px solid #dee2e6'}}
                                                                    disabled={user_check}
                                                                    checked={user_check? false :user_allergylist.includes(allergy.allergy_id)}
                                                                    defaultChecked={props.useredit.user_allergy.includes(allergy.allergy_id)?true:null}
                                                                    onChange={(e)=>{
                                                                        const allergyId = allergy.allergy_id;
                                                                        if (e.target.checked) {
                                                                            // 체크된 경우 알러지 ID를 추가
                                                                            setUserAllergyList((prev) => [...prev, allergyId]);
                                                                        } else {
                                                                            // 체크 해제된 경우 알러지 ID를 제거
                                                                            setUserAllergyList((prev) => prev.filter((id) => id !== allergyId));
                                                                        }
                                                                    }}
                                                                />
                                                                <Form.Check.Label>{allergy.allergy}</Form.Check.Label>
                                                        </Form.Check>
                                                    )))
                                                    :null}
                                            </div>
                                            <Btn onClick={()=>{
                                                // Body scroll hidden 제거
                                                document.body.style.overflow = "unset";
                                                return handleAllergyCheck()
                                            }} variant="primary" size="sm" type="button" className="full primary mt-4" value="알러지 저장" />
                                            </ModalBox>
                                        </Modal>,document.getElementById('modal-root') 
                                    )}
                                </>
                        :null}
                        {/* 사용자 고민 */}
                        <Form.Group className="column-flex mb-2" style={{alignItems:'flex-start'}}>
                                {afflictionlist?.map((affliction, index)=>(
                                    <Form.Check type='checkbox' key={index+1} id={`check-api-checkbox-${index+1}`} style={{width:'100%', display:'flex',alignItems:'center',justifyContent:'space-between', borderTop:'1px solid #eee', padding:'1rem'}}>
                                        <Form.Label className="strong" style={{marginBottom: '0'}}>{affliction.affliction}</Form.Label>
                                        {/* 비만 권장 메모 */}
                                        {affliction.affliction =='비만' && BMI_description != 0 ?<span style={{fontSize:'12px', margin:'0 8px', color: 'orangered'}}> 권장 / BMI {BMI_description}(비만) </span> :null}
                                        <Form.Check.Input type='checkbox' isValid
                                            id={affliction.affliction}
                                            checked={affliction.affliction == '비만' && BMI? true:null}
                                            style={{position:'relative', margin:0, border:'1px solid #3C3C3C', marginLeft: '8px'}}
                                            defaultChecked={props.useredit.user_affliction && props.useredit.user_affliction.some( item => item.affliction_id === affliction.affliction_id)?true:null}
                                            onChange={(e)=>{
                                                if(e.target.id=='비만'){
                                                    checkBMI(!BMI)
                                                }
                                                if (e.target.checked) {
                                                    // 체크된 경우 건강고민 ID를 추가
                                                    setUserAfflictionList((prev) => [...prev, affliction.affliction_id]);
                                                } else {
                                                    // 체크 해제된 경우 건강고민 ID를 제거
                                                    setUserAfflictionList((prev) => prev.filter((id) => id !== affliction.affliction_id));
                                                }
                                            }}
                                        />
                                    </Form.Check>
                                ))}
                                {incongruitylist?.map((incon,index)=>(
                                    <Form.Check type='checkbox' key={index+1} id={`check-api-checkbox-${index+1}`} style={{width:'100%', display:'flex',alignItems:'center',justifyContent:'space-between', borderTop:'1px solid #eee', padding:'1rem'}}>
                                    <Form.Label className="strong" style={{marginBottom: '0'}}>{incon.incongruity}</Form.Label>
                                    <Form.Check.Input type='checkbox' isValid
                                        id={incon.incongruity}
                                        style={{position:'relative', margin:0, border:'1px solid #3C3C3C', marginLeft: '8px'}}
                                        defaultChecked={props.useredit.user_incongruity && props.useredit.user_incongruity.some( item => item.incongruity_id === incon.incongruity_id)?true:null}
                                        onChange={(e)=>{
                                            if (e.target.checked) {
                                                // 체크된 경우 건강고민 ID를 추가
                                                setUserIncongruityList((prev) => [...prev, incon.incongruity_id]);
                                            } else {
                                                // 체크 해제된 경우 건강고민 ID를 제거
                                                setUserIncongruityList((prev) => prev.filter((id) => id !== incon.incongruity_id));
                                            }
                                        }}
                                    />
                                    </Form.Check>
                                ))
                                }
                            </Form.Group>
                        </HealthBox>
                    </Form.Group>
                </Offcanvas.Body>
                <Btn onClick={()=>{UserDeleteValid()}} variant="primary" size="lg" className="full lightgray mt-4" value="삭제" />
                <Btn variant="primary" size="lg" type="submit" className="full primary mt-4 pinegreen" value="저장" />
            </Form>
            </Wrapper>
        </Offcanvas>
        </>
    )
}
export default UserEdit;